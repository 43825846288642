import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { Grid, TextField, Card, Link, InputAdornment, makeStyles, Theme, createStyles } from '@material-ui/core'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
      width: 500,
      position: 'relative',
      minHeight: 200,

    },
    textField: {
      "& .MuiTypography-colorTextSecondary": {
        color: "#BA850A"
      }

    }
  }),
);


function App() {
  const classes = useStyles();

  const [email, setEmail] = useState("");

  useEffect(() => {

    const url = new URL(
      window.location.href
    );
    setEmail((): any => url.searchParams.get("email"))

  }, [])


  return (
    <div style={{
      backgroundColor: "#ececec", height: "100vh", display: "flex", justifyContent: "center"
    }}>
      <Card style={{ width: "400px", height: "auto", textAlign: "center", position: "absolute", top: "20%" }}>
        <header>
          <div style={{ padding: "40px 40px 0px 40px" }}>
            <img src="https://eosnation.io/wp-content/uploads/2020/11/logo_300.png" style={{ height: "60px", paddingBottom: "22px" }} />
            <div style={{ fontSize: "24px" }}>
              Verify your email
            </div>
            <div style={{ fontSize: "14px", marginTop: "8px" }}>
              We’ve sent you a verification link to your email
            </div>
          </div>
        </header>
        <div style={{ paddingTop: "70px", paddingBottom: "70px", padding: "40px" }}>
          <TextField
            label="Email"
            id="outlined-email"
            variant="outlined"
            defaultValue={email}
            value={email}
            className={classes.textField}
            InputProps={{ endAdornment: <InputAdornment position="end" style={{ color: "#BA850A" }}>Unverified</InputAdornment> }}
            style={{ width: "100%" }}
            onChange={(e) => setEmail(() => email)}
          // disabled
          />
        </div>
        {/* <div style={{ padding: "0px 40px 40px 40px" }}>
          <div style={{ fontSize: "19px", marginTop: "8px", paddingBottom: "8px" }}>
            Didn't receive an email?
          </div>
          <Link style={{ cursor: "pointer", textDecoration: "none" }}>
            Resend Email
          </Link>
        </div> */}
      </Card>
    </div>
  );
}

export default App;
